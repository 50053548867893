import Logo from './Logo';
import Login from './Login';
import { useAppContext } from '../appContext';
import Icon, { IconType } from './Icon';
import { useEffect, useRef, useState } from 'react';
import { signOutUser } from '../firebase/firebaseUtils';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  showMenu?: boolean;
  showProfile?: boolean;
}

export default function Header({
  showMenu = true,
  showProfile = true,
}: HeaderProps) {
  const navigate = useNavigate();
  const { globalState, updateGlobalState } = useAppContext();
  const [isProfileMenuVisible, setProfileMenuVisibility] = useState(false);
  const profileContainerRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        profileContainerRef.current &&
        !profileContainerRef.current.contains(event.target)
      ) {
        setProfileMenuVisibility(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <header>
        <a href="/">
          <Logo />
        </a>

        <nav className="menu">
          <ul>
            {showMenu && (
              <>
                <li>
                  <a href="/#features">Features</a>
                </li>
                <li>
                  <a href="/#how-to-use">How to use</a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
              </>
            )}
          </ul>

          <ul>
            {showProfile && (
              <>
                {globalState.user ? (
                  <li className="profile-container" ref={profileContainerRef}>
                    <div
                      className="profile"
                      onClick={() => {
                        setProfileMenuVisibility(true);
                      }}
                    >
                      <img
                        src={globalState.user.photoURL || ''}
                        alt={globalState.user.displayName || 'User avatar'}
                      />
                      <Icon icon={IconType.EXPAND_MORE} />
                    </div>
                    {isProfileMenuVisible && (
                      <div className="profile-menu">
                        <div className="profile-info">
                          <img
                            src={globalState.user.photoURL || ''}
                            alt={globalState.user.displayName || 'User avatar'}
                          />
                          <div>
                            <p className="profile-name">
                              {globalState.user.displayName}
                            </p>
                            <p className="profile-status">
                              {globalState.user.email}
                            </p>
                          </div>
                        </div>
                        <ul>
                          <li className="hidden">
                            <a href="#">Sign In</a>
                          </li>
                          <li className="hidden">
                            <a href="#">Create an account</a>
                          </li>
                          <li className="hidden">
                            <a href="#">Settings game</a>
                          </li>
                          <li className="divider hidden"></li>
                          <li>
                            <a
                              onClick={() =>
                                signOutUser(() => {
                                  navigate('/');
                                })
                              }
                            >
                              Sign out
                            </a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </li>
                ) : (
                  <li>
                    <a
                      onClick={() => {
                        updateGlobalState({ isLoginModalVisible: true });
                      }}
                    >
                      Login
                    </a>
                  </li>
                )}
                <li>
                  <a
                    className="hidden"
                    onClick={() => {
                      updateGlobalState({ isLoginModalVisible: true });
                    }}
                  >
                    Sign Up
                  </a>
                </li>
              </>
            )}
          </ul>
        </nav>
      </header>
      <Login />
    </>
  );
}
