import ReactDOM from 'react-dom/client';
import { AppProvider } from './appContext.tsx';
import Routes from './routes.tsx';

import './style/styles.scss';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <AppProvider>
    <Routes />
  </AppProvider>
);
