import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getAnalytics } from 'firebase/analytics';
import { GoogleAuthProvider, GithubAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDNjQO3HjRnaXCxPp0X44ZL1zhyQlDOkAw',
  authDomain: 'planning-poker-ef7ee.firebaseapp.com',
  databaseURL: 'https://planning-poker-ef7ee-default-rtdb.firebaseio.com',
  projectId: 'planning-poker-ef7ee',
  storageBucket: 'planning-poker-ef7ee.appspot.com',
  messagingSenderId: '658670235252',
  appId: '1:658670235252:web:6f9fa5aa060ca92adfeb21',
  measurementId: 'G-C478115XGP',
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
const db = getDatabase(firebaseApp);
const googleAuthProvider = new GoogleAuthProvider();
const githubAuthProvider = new GithubAuthProvider();

export { db, analytics, googleAuthProvider, githubAuthProvider };
