import { useNavigate } from 'react-router-dom';
import LogoFooter from '../image/logo-footer.svg';
import TrackerEvent from '../analytics/TrackerEvent';
import { createGame } from '../firebase/firebaseUtils';

export default function Footer() {
  const navigate = useNavigate();

  const startGame = async () => {
    try {
      TrackerEvent.sendStartGameWithHomeMainButton();
      await createGame((key) => {
        navigate(`/${key}`);
      });
    } catch (error) {
      console.error('Error writing data:', error);
    }
  };

  return (
    <footer>
      <div className="content">
        <div className="brand">
          <a href="/">
            <img src={LogoFooter} alt="Scrum Poker Pro" />
          </a>
          <p>
            Start optimizing now for efficient collaboration and precision in
            every sprint!
          </p>
        </div>
        <div className="links">
          <div>
            <h3>Product</h3>
            <ul>
              <li>
                <a onClick={startGame}>Start new game</a>
              </li>
              <li>
                <a href="/faq">FAQs</a>
              </li>
            </ul>
          </div>
          <div>
            <h3>Community</h3>
            <ul>
              <li className="hidden">
                <a href="">Contact Us</a>
              </li>
              <li>
                <a href="/about">About</a>
              </li>
            </ul>
          </div>
          <div>
            <h3>Legal</h3>
            <ul>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/terms-and-conditions">Term of condition</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
