import { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import User from '../models/User';
import {
  resetGame,
  setGameStatus,
  setUserData,
  setUserFlips,
  setUserPoints,
  setupGameListeners,
} from '../firebase/firebaseUtils';
import Header from '../components/Header';
import { GameStatus } from '../enum/GameStatus';
import Modal from '../components/Modal';
import Icon, { IconType } from '../components/Icon';

export default function GamePage() {
  const { gameId } = useParams();
  const [isModalVisible, setModalVisibility] = useState(true);
  const [isShareModalVisible, setShareModalVisibility] = useState(false);
  const [isUrlShareCopied, setUrlShareCopied] = useState(false);
  const [pageGameStatus, setPageGameStatus] = useState(GameStatus.Vote);
  const [username, setUsername] = useState('');
  const [shareUrl, setShareUrl] = useState('');
  const [error, setError] = useState('');
  const [selectedButton, setSelectedButton] = useState('');
  const pointsValues = useMemo(() => ['0.5', '1', '2', '3', '5', '☕'], []);
  const [countdown, setCountdown] = useState(3);
  const [users, setUsers] = useState<Array<User>>([]);
  const [average, setAverage] = useState('');
  const [pointsResults, setPointsResults] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Para evitar a submissão do formulário (se houver)
      registerPlayer();
    }
  };

  const registerPlayer = async () => {
    try {
      if (!username.trim()) {
        setError('This field cannot be empty.');
        return;
      }

      const storedUsername = localStorage.getItem(gameId!);
      if (
        username != storedUsername &&
        users.find((user) => user.name === username)
      ) {
        setError('Username is already in use.');
        return;
      }

      await setUserData(gameId!, username);
      setModalVisibility(false);
      localStorage.setItem(gameId!, username);
    } catch (error) {
      console.error('Error writing data:', error);
    }
  };

  const registerGameStatus = useCallback(
    async (status: GameStatus) => {
      try {
        setPageGameStatus(status);
        await setGameStatus(gameId!, status);
      } catch (error) {
        console.error('Error writing data:', error);
      }
    },
    [gameId]
  );

  const startNewVoting = async () => {
    try {
      await resetGame(gameId!, users);
    } catch (error) {
      console.error('Error writing data:', error);
    }
  };

  const registerUserFlip = async (flips: number) => {
    try {
      await setUserFlips(gameId!, flips);
    } catch (error) {
      console.error('Error writing data:', error);
    }
  };

  const renderPointButtons = () => {
    return pointsValues.map((value) => (
      <button
        key={value}
        className={selectedButton === value ? 'selected' : ''}
        onClick={async () => {
          try {
            const selectedValue = selectedButton === value ? '' : value;
            setSelectedButton(selectedValue);
            await setUserPoints(gameId!, selectedValue);
          } catch (error) {
            console.error('Error writing data:', error);
          }
        }}
      >
        {value}
      </button>
    ));
  };

  const renderResults = () => {
    const sortedResults = pointsValues
      .map((value, index) => ({
        value,
        votes: pointsResults[index],
      }))
      .filter((result) => result.votes > 0)
      .sort((a, b) => a.votes - b.votes);

    if (sortedResults.length == 0) return;

    const maxVotes = sortedResults[sortedResults.length - 1].votes;

    return sortedResults.map((result, index) => {
      const sizeBar = {
        height: `${(result.votes / maxVotes) * 60}px`,
      };

      return (
        <div key={index}>
          <p>{result.votes}</p>
          <span className="bar" style={sizeBar}></span>
          <div className="card">
            <p>{result.value}</p>
          </div>
        </div>
      );
    });
  };

  const renderTable = () => {
    if (pageGameStatus == GameStatus.Countdown) {
      return <p className="countdown">{countdown}</p>;
    } else if (pageGameStatus == GameStatus.Vote && selectedButton != '') {
      return (
        <button
          className="primary"
          onClick={() => registerGameStatus(GameStatus.Countdown)}
        >
          Reveal cards
        </button>
      );
    } else if (pageGameStatus == GameStatus.Reveal) {
      return (
        <button className="secondary" onClick={startNewVoting}>
          Start new voting
        </button>
      );
    } else {
      return <p>Pick your cards!</p>;
    }
  };

  const renderPlayer = (index: number) => {
    if (users[index]) {
      return (
        <div className="player">
          <div
            style={{ transform: `rotateY(${users[index].flips * 180}deg)` }}
            onClick={() => {
              const storedUsername = localStorage.getItem(gameId!);
              if (
                pageGameStatus != GameStatus.Countdown &&
                pageGameStatus != GameStatus.Reveal &&
                storedUsername == users[index].name
              ) {
                registerUserFlip(users[index].flips + 1);
              }
            }}
            className={
              users[index].points == null || users[index].points === ''
                ? 'card'
                : pageGameStatus == GameStatus.Reveal
                  ? 'card reveal'
                  : 'card ready'
            }
          >
            <p>
              {pageGameStatus == GameStatus.Reveal ? users[index].points : ''}
            </p>
          </div>
          <p>{users[index].name}</p>
        </div>
      );
    }
  };

  const allUsersVoted = (users: Array<User>) => {
    return users.every(
      (user) =>
        user.points != null && user.points != undefined && user.points !== ''
    );
  };

  const usernameIsEmpty = () => {
    const storedUsername = localStorage.getItem(gameId!);
    return storedUsername == null || storedUsername == '';
  };

  useEffect(() => {
    setupGameListeners(gameId!, (data) => {
      setUsers(data.users);

      if (data.status != undefined) setPageGameStatus(data.status);

      if (
        data.users.length > 0 &&
        allUsersVoted(data.users) &&
        data.status == GameStatus.Vote
      ) {
        registerGameStatus(GameStatus.Countdown);
      }

      switch (data.status) {
        case GameStatus.Countdown: {
          setCountdown(3);

          setTimeout(() => {
            setCountdown(2);
          }, 1000);

          setTimeout(() => {
            setCountdown(1);
          }, 2000);

          setTimeout(() => {
            setCountdown(0);
            registerGameStatus(GameStatus.Reveal);
          }, 3000);

          break;
        }
        case GameStatus.Reveal: {
          const pointsSum = data.users.reduce((acc, user) => {
            const userPoints = Number(user.points);
            if (!isNaN(userPoints)) {
              return acc + userPoints;
            }
            return acc;
          }, 0);

          const usersCount = data.users.reduce((count, user) => {
            const userPoints = Number(user.points);
            if (!isNaN(userPoints) && userPoints > 0) {
              return count + 1;
            }
            return count;
          }, 0);

          const newPointsResults = [0, 0, 0, 0, 0, 0, 0, 0, 0];
          data.users.forEach((user) => {
            const index = pointsValues.findIndex(
              (value) => value === user.points
            );
            newPointsResults[index] += 1;
          });

          setPointsResults(newPointsResults);
          setAverage((pointsSum / usersCount).toFixed(1).toString());
          setSelectedButton('');
          break;
        }
      }
    });
  }, [gameId, pointsValues, registerGameStatus]);

  return (
    <>
      <Header showMenu={false} />
      <main className="game-page">
        <div className="game">
          <div className={`share-table ${users.length > 1 ? 'hidden' : ''}`}>
            <p>Feeling lonely ? 😴</p>
            <a
              onClick={() => {
                setShareUrl(window.location.href);
                setShareModalVisibility(true);
              }}
            >
              Invite players
            </a>
          </div>
          <div className="table">{renderTable()}</div>
          <div className="seats bottom">
            {renderPlayer(0)}
            {renderPlayer(4)}
            {renderPlayer(8)}
          </div>
          <div className="seats top">
            {renderPlayer(1)}
            {renderPlayer(5)}
            {renderPlayer(9)}
          </div>
          <div className="seats left">
            {renderPlayer(2)}
            {renderPlayer(6)}
          </div>
          <div className="seats right">
            {renderPlayer(3)}
            {renderPlayer(7)}
          </div>
        </div>

        {pageGameStatus == GameStatus.Vote && (
          <div className="points">
            <p>Choose your card 👇</p>
            <div>{renderPointButtons()}</div>
          </div>
        )}

        {pageGameStatus == GameStatus.Reveal && (
          <div className="result">
            {renderResults()}
            {average != 'NaN' && (
              <div className="average">
                <p>Average:</p>
                <p className="average-value">{average}</p>
              </div>
            )}
          </div>
        )}
      </main>
      <Modal
        isVisible={isModalVisible && usernameIsEmpty()}
        title="Choose your display name"
        contentClass="add-player-modal"
      >
        <input
          type="text"
          placeholder="Your display name"
          maxLength={14}
          value={username}
          className={error && 'error'}
          onChange={(e) => {
            setUsername(e.target.value);
            setError('');
          }}
          onKeyDown={handleKeyDown}
          autoFocus
        />
        {error && <p className="error">{error}</p>}
        <button className="primary square" onClick={registerPlayer}>
          Continue to game
          <Icon icon={IconType.ARROW_FORWARD} />
        </button>
      </Modal>

      <Modal
        isVisible={isShareModalVisible}
        title="Invite players"
        contentClass="share-modal"
        onClose={() => {
          setUrlShareCopied(false);
          setShareModalVisibility(false);
        }}
      >
        <input type="text" value={shareUrl} autoFocus />
        <button
          className={`primary square ${isUrlShareCopied && 'copied'}`}
          onClick={() => {
            navigator.clipboard.writeText(shareUrl);
            setUrlShareCopied(true);
          }}
        >
          {isUrlShareCopied ? 'Copied! 👍' : 'Copy invitation link'}
        </button>
      </Modal>
    </>
  );
}
