import { db, githubAuthProvider, googleAuthProvider } from './firebase';
import {
  ref,
  set,
  onValue,
  update,
  DataSnapshot,
  onDisconnect,
  push,
} from 'firebase/database';
import User from '../models/User';
import { getUserId } from '../utils/userUtils';
import GameData from '../models/GameData';
import { GameStatus } from '../enum/GameStatus';
import { getAuth, signInWithRedirect, signOut } from 'firebase/auth';

export function createGame(
  onGameCreated: (key: string) => void
): Promise<void> {
  return push(ref(db, 'games/')).then((snapshot) => {
    if (!snapshot.key) throw new Error('Game key is null');

    const gameKey: string = snapshot.key;

    setGameStatus(gameKey, GameStatus.Vote).then(() => {
      onGameCreated(gameKey);
    });
  });
}

export function openLoginGoogle() {
  const auth = getAuth();
  signInWithRedirect(auth, googleAuthProvider);
}

export function openLoginGithub() {
  const auth = getAuth();
  signInWithRedirect(auth, githubAuthProvider);
}

export function signOutUser(callback: () => void) {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      callback();
    })
    .catch((error) => {
      console.error(error);
    });
}

export function setUserData(gameId: string, username: string): Promise<void> {
  const userId = getUserId();
  if (userId == undefined) return Promise.reject('User id is undefined');

  const userRef = ref(db, `games/${gameId}/users/${userId}`);

  const disconnectRef = onDisconnect(userRef);

  // Remover dados do usuário quando desconectado
  disconnectRef.remove();

  // Se a conexão for perdida, este bloco será executado
  disconnectRef.cancel().catch((error) => {
    console.error('Connection lost:', error);
    // Aqui você pode adicionar lógica para lidar com a perda de conexão
  });

  return set(userRef, {
    id: userId,
    name: username,
  });
}

export function setUserPoints(gameId: string, point: string): Promise<void> {
  const userId = getUserId();
  if (userId == undefined) return Promise.reject('User id is undefined');

  const username = localStorage.getItem(gameId);
  console.log(`username: ${username}`);

  return update(ref(db, `games/${gameId}/users/${userId}`), {
    points: point,
    name: username,
  });
}

export function setUserFlips(gameId: string, flips: number): Promise<void> {
  const userId = getUserId();
  if (userId == undefined) return Promise.reject('User id is undefined');

  const username = localStorage.getItem(gameId);
  console.log(`username: ${username}`);

  return update(ref(db, `games/${gameId}/users/${userId}`), {
    flips: flips,
    name: username,
  });
}

export function setGameStatus(
  gameId: string,
  status: GameStatus
): Promise<void> {
  return set(ref(db, `games/${gameId}/status`), status);
}

export function resetGame(gameId: string, users: User[]): Promise<void> {
  console.log('resetGame -------');
  console.log(`gameId: ${gameId}`);
  console.log(`users: ${JSON.stringify(users)}`);
  const updates: {
    [key: string]: string | number;
  } = {};
  users.forEach((user) => {
    updates[`games/${gameId}/users/${user.id}/points`] = '';
    updates[`games/${gameId}/users/${user.id}/flips`] = 0;
  });

  updates[`games/${gameId}/status`] = GameStatus.Vote;

  return update(ref(db), updates);
}

export function setupGameListeners(
  gameId: string,
  onDataUpdate: (data: GameData) => void
): void {
  const starCountRef = ref(db, `games/${gameId}`);
  onValue(starCountRef, (snapshot: DataSnapshot) => {
    const data = snapshot.val();
    if (!data) return;

    const usersArray = data.users
      ? Object.keys(data.users)
          .map((key) => {
            const user = data.users[key];
            return new User(
              user.id,
              user.name,
              user.points,
              user.flips ? user.flips : 0
            );
          })
          .filter((user) => user.name != undefined)
          .filter((user) => user.id != undefined)
      : [];

    const gameData = new GameData(usersArray, data.status);

    onDataUpdate(gameData);
  });
}
