import { openLoginGithub, openLoginGoogle } from '../firebase/firebaseUtils';
import Modal from './Modal';
import LogoGoogle from '../image/logo-google.svg';
import LogoGithub from '../image/logo-github.svg';
import LogoMagicLink from '../image/logo-magic-link.svg';
import LogoPassword from '../image/logo-password.svg';
import { useAppContext } from '../appContext';
import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export default function Login() {
  const { globalState, updateGlobalState } = useAppContext();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      updateGlobalState({ user: user });
    });

    return () => unsubscribe();
  }, []);

  const closeLoginModal = () => {
    updateGlobalState({ isLoginModalVisible: false });
  };

  return (
    <Modal
      isVisible={globalState.isLoginModalVisible}
      onClose={closeLoginModal}
      title="Sign In"
      contentClass="login"
    >
      <button onClick={openLoginGoogle}>
        Google
        <img src={LogoGoogle} />
      </button>
      <button onClick={openLoginGithub}>
        Github
        <img src={LogoGithub} />
      </button>
      <button onClick={openLoginGoogle} className="hidden">
        Email Magic Link
        <img src={LogoMagicLink} />
      </button>
      <button onClick={openLoginGoogle} className="hidden">
        Password
        <img src={LogoPassword} />
      </button>
      {/* <div className="create-account">
            <p>Don't have an account?</p>
            <a href="#">Create an account</a>
          </div> */}
      <div className="terms">
        <p>
          By signing into scrum poker online, you agree to our
          <br />
          <a href="/terms-and-conditions">Terms of Services</a> and{' '}
          <a href="/privacy-policy">Privacy Statement</a>
        </p>
      </div>
    </Modal>
  );
}
