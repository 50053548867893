import Icon, { IconType } from './Icon';

interface ModalProps {
  isVisible: boolean;
  onClose?: () => void;
  title?: string;
  children?: React.ReactNode;
  contentClass?: string;
}

export default function Modal({
  isVisible,
  onClose,
  title,
  children,
  contentClass,
}: ModalProps) {
  return (
    <>
      {isVisible && (
        <div className="modal">
          <div className="cover"></div>
          <div className={`content ${contentClass}`}>
            <div className="header">
              {title && <h2>{title}</h2>}
              {onClose && (
                <Icon
                  icon={IconType.CLOSE}
                  iconClass="close-button"
                  onClose={onClose}
                />
              )}
            </div>
            {children}
          </div>
        </div>
      )}
    </>
  );
}
