export enum GameStatus {
    Vote = "vote",
    Countdown = "countdown",
    Reveal = "reveal",
    Unknown = "unknown",
}

export function stringToGameStatus(value: string): GameStatus {
    switch (value) {
        case "vote":
        case undefined:
            return GameStatus.Vote;
        case "countdown":
            return GameStatus.Countdown;
        case "reveal":
            return GameStatus.Reveal;
        default:
            throw new Error(`Unknown game status: ${value}`);
    }
}