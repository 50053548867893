export default class User {
  id: string;
  name: string;
  points: string;
  flips: number;

  constructor(id: string, name: string, points: string, flips: number) {
    this.id = id;
    this.name = name;
    this.points = points;
    this.flips = flips;
  }
}
