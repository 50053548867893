import Footer from '../components/Footer';
import Header from '../components/Header';

export default function TermsConditionsPage() {
  return (
    <>
      <Header />
      <article>
        <h1>Terms and Conditions</h1>
        <p className="lastupdate">Last Updated: November 22, 2023</p>

        <p>
          Welcome to Scrumpoker Online ("we," "our," or "the site"). By
          accessing or using our website, you agree to comply with these Terms
          and Conditions and all applicable laws and regulations. If you do not
          agree with any of these terms, please do not use our site.
        </p>

        <h2>1. Authorized Use:</h2>

        <p>
          1.1. You agree to use our site only for lawful purposes and in
          accordance with these Terms and Conditions.
        </p>

        <p>
          1.2. You are responsible for ensuring that all information provided
          during the use of the site is accurate and up-to-date.
        </p>

        <h2>2. Intellectual Property:</h2>

        <p>
          2.1. All content on our site, including text, graphics, logos, images,
          and software, is the exclusive property of Scrumpoker Online and is
          protected by copyright laws.
        </p>

        <p>
          2.2. You agree not to reproduce, distribute, modify, display, or
          create derivative works from the content of our site without our
          express written permission.
        </p>

        <h2>3. User Accounts:</h2>

        <p>
          3.1. If you create an account on our site, you are responsible for
          maintaining the confidentiality of your account credentials and all
          activities that occur under your account.
        </p>

        <p>
          3.2. We reserve the right to suspend or terminate accounts, refuse
          services, and remove or edit content at our discretion.
        </p>

        <h2>4. Links to Other Sites:</h2>

        <p>
          4.1. Our site may contain links to third-party websites. We are not
          responsible for the content of these sites and do not endorse or
          guarantee their accuracy, security, or reliability.
        </p>

        <h2>5. Limitation of Liability:</h2>
        <p>
          5.1. Your use of our site is at your own risk. We do not guarantee
          that the site will be error-free or that access will be uninterrupted.
        </p>

        <p>
          5.2. In no event, including negligence, shall we be liable for any
          direct, indirect, incidental, special, or consequential damages
          resulting from the use or inability to use our site.
        </p>

        <h2>6. Changes to these Terms:</h2>

        <p>
          6.1. We reserve the right to modify these Terms and Conditions at any
          time. The most recent version will always be available on our site.
        </p>

        <h2>7. Governing Law:</h2>
        <p>
          7.1. These Terms and Conditions are governed and interpreted in
          accordance with the laws of Brazil, and you agree to the exclusive
          jurisdiction of the courts of that jurisdiction.
        </p>

        <p>
          By continuing to use our site, you agree to these Terms and
          Conditions. If you do not agree with them, please stop using our site
          immediately.
        </p>
      </article>
      <Footer />
    </>
  );
}
