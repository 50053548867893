import Footer from '../components/Footer';
import Header from '../components/Header';

export default function PrivacyPage() {
  return (
    <>
      <Header />
      <article>
        <h1>Privacy Policy</h1>

        <p className="lastupdate">Last Updated: November 22, 2023</p>

        <p>
          Welcome to Scrumpoker Online ("we," "our," or "the site"). This
          Privacy Policy explains how we collect, use, disclose, and protect
          personal information from users ("you") of our website.
        </p>

        <h2>1. Information We Collect:</h2>
        <h3>1.1. Personal Information:</h3>
        <p>
          We may collect personal information, such as your name and email
          address, when voluntarily provided by you during your use of our site.
        </p>

        <h3>1.2. Usage Information:</h3>
        <p>
          We automatically collect information about how you interact with our
          site, including your IP address, browser type, pages visited, and
          access times.
        </p>

        <h2>2. Use of Your Information:</h2>
        <h3>2.1. Service Provision:</h3>
        <p>
          We use your information to provide and enhance the services offered on
          our site, including Scrumpoker Online.
        </p>

        <h3>2.2. Communications:</h3>
        <p>
          We may contact you to provide information about site updates, new
          features, or in response to your inquiries.
        </p>

        <h2>3. Sharing of Information:</h2>
        <h3>3.1. Partners and Service Providers:</h3>
        <p>
          We may share your information with third parties that assist us in
          providing and improving our services.
        </p>

        <h3>3.2. Legal Requirements:</h3>
        <p>
          We may disclose your personal information in response to legal
          processes or when we believe it is necessary to comply with the law.
        </p>

        <h2>4. Cookies and Similar Technologies:</h2>
        <h3>4.1. Cookies:</h3>
        <p>
          We use cookies and similar technologies to enhance user experience,
          analyze site usage, and personalize content.
        </p>

        <h2>5. Security:</h2>
        <h3>5.1. Security Measures:</h3>
        <p>
          We implement security measures to protect your information from
          unauthorized access or disclosure.
        </p>

        <h2>6. Your Rights:</h2>
        <h3>6.1. Access and Correction:</h3>
        <p>
          You have the right to access and correct your personal information
          held by us.
        </p>

        <h2>7. Changes to this Privacy Policy:</h2>
        <h3>7.1. Updates:</h3>
        <p>
          We reserve the right to update this policy periodically. The date of
          the last update will be indicated at the beginning of the policy.
        </p>

        <h2>8. Contact:</h2>
        <h3>8.1. Questions:</h3>
        <p>
          If you have questions or concerns about this policy, please contact us
          at <u>eric.havel@gmail.com</u>.
        </p>

        <p>
          By continuing to use our site, you agree to this privacy policy. We
          recommend reviewing this policy regularly to stay informed about how
          we protect your information.
        </p>
      </article>
      <Footer />
    </>
  );
}
