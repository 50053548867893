export default function Logo() {
  return (
    <svg
      className="logo"
      width="217"
      height="40"
      viewBox="0 0 217 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.86235 0H25.675C28.0543 0 30 1.90186 30 4.24793L29.9832 31.186C29.9832 32.3817 29.4822 33.462 28.6759 34.2339C28.6518 34.257 24.0733 38.8458 24.0486 38.8684C23.2801 39.5707 22.2513 40 21.1208 40H4.30823C1.92886 40 0 38.0981 0 35.7521V8.81396C0 7.7586 0.390315 6.79315 1.03637 6.05025C1.07069 6.0108 10.1401 -3.07733 5.62172 1.45053C6.40888 0.609505 7.61016 0 8.86235 0Z"
        fill="black"
      />
      <path
        d="M7 4.07216C7 2.92774 7.94091 2 9.10158 2H25.9142C27.0749 2 28.0158 2.92774 28.0158 4.07216V31.0103C28.0158 32.1547 27.0749 33.0824 25.9142 33.0824H9.10158C7.94091 33.0824 7 32.1547 7 31.0103V4.07216Z"
        fill="white"
      />
      <path
        d="M19.435 15.5431C19.3982 15.1384 19.2345 14.8238 18.9439 14.5994C18.6569 14.3713 18.2466 14.2572 17.7131 14.2572C17.3599 14.2572 17.0656 14.3032 16.8301 14.3952C16.5946 14.4872 16.418 14.6141 16.3003 14.776C16.1826 14.9342 16.1219 15.1163 16.1182 15.3224C16.1108 15.4916 16.1439 15.6406 16.2175 15.7694C16.2948 15.8982 16.4052 16.0122 16.5487 16.1116C16.6958 16.2072 16.8724 16.2919 17.0785 16.3654C17.2845 16.439 17.5163 16.5034 17.7738 16.5586L18.7452 16.7794C19.3044 16.9008 19.7974 17.0627 20.2242 17.265C20.6547 17.4674 21.0153 17.7084 21.3059 17.988C21.6003 18.2676 21.8229 18.5896 21.9737 18.9538C22.1246 19.3181 22.2018 19.7265 22.2055 20.179C22.2018 20.8928 22.0216 21.5054 21.6647 22.0168C21.3078 22.5282 20.7945 22.9201 20.1249 23.1923C19.459 23.4646 18.655 23.6007 17.7131 23.6007C16.7676 23.6007 15.9434 23.4591 15.2407 23.1758C14.5379 22.8925 13.9916 22.462 13.6016 21.8844C13.2116 21.3067 13.011 20.5764 13 19.6933H15.616C15.638 20.0576 15.7355 20.3611 15.9085 20.604C16.0814 20.8468 16.3187 21.0308 16.6204 21.1559C16.9258 21.281 17.279 21.3435 17.68 21.3435C18.048 21.3435 18.3607 21.2938 18.6182 21.1945C18.8795 21.0951 19.08 20.9572 19.2198 20.7806C19.3596 20.604 19.4314 20.4016 19.435 20.1735C19.4314 19.9601 19.3651 19.778 19.2364 19.6271C19.1076 19.4726 18.9089 19.3401 18.6403 19.2298C18.3754 19.1157 18.0369 19.0108 17.6248 18.9152L16.4438 18.6392C15.4651 18.4148 14.6943 18.0524 14.1314 17.552C13.5684 17.048 13.2888 16.3673 13.2925 15.51C13.2888 14.811 13.4765 14.1984 13.8554 13.6722C14.2344 13.1461 14.7587 12.7359 15.4283 12.4415C16.0979 12.1472 16.8614 12 17.7187 12C18.5943 12 19.3541 12.149 19.998 12.447C20.6455 12.7414 21.1477 13.1553 21.5046 13.6888C21.8615 14.2223 22.0436 14.8404 22.051 15.5431H19.435Z"
        fill="black"
      />
      <path
        className="effect"
        d="M206.189 29.9999V14.7272H211.519V17.6306H211.678C211.956 16.5567 212.397 15.7712 213 15.2741C213.61 14.7769 214.322 14.5283 215.138 14.5283C215.376 14.5283 215.612 14.5482 215.844 14.588C216.082 14.6211 216.311 14.6708 216.53 14.7371V19.4204C216.258 19.3276 215.923 19.258 215.526 19.2116C215.128 19.1652 214.787 19.142 214.501 19.142C213.965 19.142 213.481 19.2646 213.05 19.5099C212.626 19.7485 212.291 20.0866 212.046 20.5241C211.8 20.9549 211.678 21.462 211.678 22.0454V29.9999H206.189Z"
        fill="black"
      />
      <path
        className="effect"
        d="M196.472 30.2783C194.841 30.2783 193.436 29.9668 192.256 29.3437C191.082 28.7139 190.178 27.8124 189.541 26.6391C188.912 25.4592 188.597 24.0473 188.597 22.4033C188.597 20.8257 188.915 19.4469 189.551 18.267C190.188 17.087 191.086 16.1689 192.246 15.5127C193.406 14.8564 194.775 14.5283 196.352 14.5283C197.506 14.5283 198.55 14.7073 199.484 15.0653C200.419 15.4232 201.218 15.9436 201.881 16.6263C202.544 17.3025 203.054 18.1244 203.412 19.0922C203.77 20.06 203.949 21.1505 203.949 22.3635V23.6363H190.307V20.6135H198.898C198.891 20.176 198.779 19.7916 198.56 19.4601C198.348 19.1221 198.059 18.8602 197.695 18.6746C197.337 18.4824 196.929 18.3863 196.472 18.3863C196.028 18.3863 195.62 18.4824 195.249 18.6746C194.877 18.8602 194.579 19.1188 194.354 19.4502C194.135 19.7816 194.019 20.1694 194.006 20.6135V23.8749C194.006 24.3654 194.109 24.8029 194.314 25.1874C194.52 25.5719 194.814 25.8735 195.199 26.0922C195.583 26.311 196.047 26.4204 196.591 26.4204C196.969 26.4204 197.314 26.3673 197.625 26.2613C197.943 26.1552 198.215 26.0028 198.44 25.8039C198.666 25.5984 198.832 25.3531 198.938 25.0681H203.949C203.777 26.1287 203.369 27.0501 202.726 27.8323C202.083 28.6079 201.231 29.2111 200.171 29.642C199.117 30.0662 197.884 30.2783 196.472 30.2783Z"
        fill="black"
      />
      <path
        className="effect"
        d="M177.734 26.3806L177.774 19.8578H178.49L182.189 14.7271H188.353L182.149 22.7215H180.598L177.734 26.3806ZM172.802 29.9999V9.63623H178.291V29.9999H172.802ZM182.228 29.9999L178.728 23.9544L182.308 20.0567L188.512 29.9999H182.228Z"
        fill="black"
      />
      <path
        className="effect"
        d="M162.66 30.2783C161.003 30.2783 159.584 29.9502 158.404 29.2939C157.224 28.6311 156.319 27.7097 155.69 26.5297C155.06 25.3432 154.745 23.9677 154.745 22.4033C154.745 20.8389 155.06 19.4668 155.69 18.2868C156.319 17.1003 157.224 16.1789 158.404 15.5226C159.584 14.8598 161.003 14.5283 162.66 14.5283C164.317 14.5283 165.736 14.8598 166.916 15.5226C168.095 16.1789 169 17.1003 169.63 18.2868C170.26 19.4668 170.575 20.8389 170.575 22.4033C170.575 23.9677 170.26 25.3432 169.63 26.5297C169 27.7097 168.095 28.6311 166.916 29.2939C165.736 29.9502 164.317 30.2783 162.66 30.2783ZM162.7 26.2215C163.164 26.2215 163.565 26.0657 163.903 25.7542C164.241 25.4426 164.503 24.9985 164.688 24.4218C164.874 23.8451 164.967 23.159 164.967 22.3635C164.967 21.5615 164.874 20.8754 164.688 20.3053C164.503 19.7286 164.241 19.2845 163.903 18.9729C163.565 18.6614 163.164 18.5056 162.7 18.5056C162.209 18.5056 161.788 18.6614 161.437 18.9729C161.086 19.2845 160.817 19.7286 160.631 20.3053C160.446 20.8754 160.353 21.5615 160.353 22.3635C160.353 23.159 160.446 23.8451 160.631 24.4218C160.817 24.9985 161.086 25.4426 161.437 25.7542C161.788 26.0657 162.209 26.2215 162.7 26.2215Z"
        fill="black"
      />
      <path
        className="effect"
        d="M137.176 29.9999V9.63623H145.966C147.477 9.63623 148.799 9.93453 149.933 10.5311C151.066 11.1277 151.948 11.9662 152.578 13.0467C153.208 14.1272 153.522 15.39 153.522 16.8351C153.522 18.2934 153.198 19.5562 152.548 20.6234C151.905 21.6907 151 22.5127 149.833 23.0894C148.673 23.6661 147.318 23.9544 145.767 23.9544H140.517V19.659H144.653C145.303 19.659 145.856 19.5463 146.314 19.3209C146.778 19.0889 147.132 18.7608 147.377 18.3365C147.629 17.9123 147.755 17.4118 147.755 16.8351C147.755 16.2518 147.629 15.7546 147.377 15.3436C147.132 14.926 146.778 14.6078 146.314 14.3891C145.856 14.1637 145.303 14.051 144.653 14.051H142.704V29.9999H137.176Z"
        fill="black"
      />
      <path
        className="effect"
        d="M105.345 29.9999V14.7272H110.555V17.6306H110.715C111.033 16.676 111.576 15.9204 112.345 15.3635C113.114 14.8067 114.029 14.5283 115.09 14.5283C116.163 14.5283 117.088 14.8134 117.864 15.3834C118.639 15.9469 119.107 16.6959 119.266 17.6306H119.425C119.683 16.6959 120.233 15.9469 121.075 15.3834C121.917 14.8134 122.905 14.5283 124.038 14.5283C125.503 14.5283 126.69 14.999 127.598 15.9403C128.513 16.8815 128.97 18.1344 128.97 19.6988V29.9999H123.482V21.0908C123.482 20.4213 123.313 19.8976 122.974 19.5198C122.636 19.1353 122.182 18.9431 121.612 18.9431C121.049 18.9431 120.601 19.1353 120.27 19.5198C119.945 19.8976 119.783 20.4213 119.783 21.0908V29.9999H114.533V21.0908C114.533 20.4213 114.364 19.8976 114.026 19.5198C113.688 19.1353 113.233 18.9431 112.663 18.9431C112.286 18.9431 111.961 19.0293 111.689 19.2016C111.417 19.374 111.205 19.6225 111.053 19.9474C110.907 20.2655 110.834 20.6467 110.834 21.0908V29.9999H105.345Z"
        fill="black"
      />
      <path
        className="effect"
        d="M97.065 23.318V14.7271H102.554V29.9998H97.3434V27.0964H97.1843C96.8529 28.0708 96.2729 28.8331 95.4443 29.3833C94.6157 29.9269 93.6313 30.1986 92.4912 30.1986C91.4239 30.1986 90.4893 29.9534 89.6872 29.4628C88.8917 28.9723 88.2719 28.2962 87.8278 27.4344C87.3903 26.5727 87.1682 25.585 87.1616 24.4714V14.7271H92.6503V23.318C92.6569 24.0736 92.8491 24.6669 93.227 25.0978C93.6114 25.5287 94.1484 25.7441 94.8378 25.7441C95.2951 25.7441 95.6896 25.648 96.021 25.4557C96.3591 25.2569 96.6176 24.9785 96.7966 24.6205C96.9822 24.2559 97.0717 23.8217 97.065 23.318Z"
        fill="black"
      />
      <path
        className="effect"
        d="M75.021 29.9999V14.7272H80.3505V17.6306H80.5096C80.788 16.5567 81.2289 15.7712 81.8321 15.2741C82.4419 14.7769 83.1545 14.5283 83.9699 14.5283C84.2085 14.5283 84.4438 14.5482 84.6758 14.588C84.9145 14.6211 85.1432 14.6708 85.3619 14.7371V19.4204C85.0901 19.3276 84.7554 19.258 84.3576 19.2116C83.9599 19.1652 83.6185 19.142 83.3335 19.142C82.7966 19.142 82.3127 19.2646 81.8818 19.5099C81.4576 19.7485 81.1228 20.0866 80.8775 20.5241C80.6323 20.9549 80.5096 21.462 80.5096 22.0454V29.9999H75.021Z"
        fill="black"
      />
      <path
        className="effect"
        d="M65.3984 30.2783C63.7412 30.2783 62.3227 29.9502 61.1427 29.2939C59.9628 28.6311 59.058 27.7097 58.4282 26.5297C57.7985 25.3432 57.4836 23.9677 57.4836 22.4033C57.4836 20.8389 57.7985 19.4668 58.4282 18.2868C59.058 17.1003 59.9628 16.1789 61.1427 15.5226C62.3227 14.8598 63.7412 14.5283 65.3984 14.5283C66.8965 14.5283 68.1924 14.8001 69.2862 15.3437C70.3866 15.8806 71.2384 16.6429 71.8416 17.6306C72.4448 18.6117 72.7497 19.7651 72.7564 21.0908H67.6655C67.5925 20.2887 67.3605 19.6789 66.9694 19.2613C66.585 18.837 66.0878 18.6249 65.478 18.6249C65.0007 18.6249 64.5831 18.7641 64.2251 19.0425C63.8672 19.3143 63.5888 19.7286 63.3899 20.2854C63.191 20.8356 63.0916 21.5283 63.0916 22.3635C63.0916 23.1988 63.191 23.8948 63.3899 24.4516C63.5888 25.0018 63.8672 25.4161 64.2251 25.6945C64.5831 25.9663 65.0007 26.1022 65.478 26.1022C65.8823 26.1022 66.2403 26.0094 66.5518 25.8238C66.8634 25.6315 67.1153 25.3531 67.3075 24.9885C67.5064 24.6173 67.6257 24.1666 67.6655 23.6363H72.7564C72.7365 24.9819 72.4282 26.1552 71.8317 27.1562C71.2351 28.1505 70.3899 28.9194 69.2961 29.463C68.209 30.0065 66.9098 30.2783 65.3984 30.2783Z"
        fill="black"
      />
      <path
        className="effect"
        d="M50.2897 16C50.2367 15.3371 49.9881 14.82 49.544 14.4488C49.1065 14.0776 48.4403 13.892 47.5454 13.892C46.9753 13.892 46.508 13.9616 46.1434 14.1008C45.7855 14.2334 45.5203 14.4157 45.348 14.6477C45.1756 14.8797 45.0861 15.1448 45.0795 15.4431C45.0662 15.6884 45.1093 15.9105 45.2088 16.1093C45.3148 16.3016 45.4805 16.4772 45.7059 16.6363C45.9313 16.7888 46.2197 16.928 46.571 17.0539C46.9223 17.1799 47.3399 17.2926 47.8238 17.392L49.4943 17.75C50.6212 17.9886 51.5857 18.3035 52.3877 18.6946C53.1898 19.0857 53.8461 19.5464 54.3565 20.0767C54.8669 20.6003 55.2414 21.1903 55.4801 21.8465C55.7253 22.5028 55.8513 23.2187 55.8579 23.9943C55.8513 25.3333 55.5165 26.4668 54.8536 27.3948C54.1908 28.3229 53.2429 29.0288 52.0099 29.5127C50.7836 29.9966 49.3087 30.2386 47.5852 30.2386C45.8153 30.2386 44.2708 29.9768 42.9517 29.4531C41.6392 28.9294 40.6183 28.124 39.8892 27.0369C39.1666 25.9431 38.802 24.5445 38.7954 22.8409H44.0454C44.0786 23.464 44.2343 23.9876 44.5127 24.4119C44.7911 24.8361 45.1822 25.1576 45.686 25.3764C46.1965 25.5951 46.803 25.7045 47.5056 25.7045C48.0956 25.7045 48.5894 25.6316 48.9872 25.4858C49.3849 25.3399 49.6865 25.1377 49.892 24.8792C50.0975 24.6207 50.2036 24.3257 50.2102 23.9943C50.2036 23.6827 50.1008 23.4109 49.9019 23.1789C49.7097 22.9403 49.3915 22.7282 48.9474 22.5426C48.5033 22.3503 47.9034 22.1714 47.1477 22.0056L45.1193 21.5681C43.3162 21.177 41.8944 20.5241 40.8536 19.6093C39.8196 18.6879 39.3058 17.4318 39.3125 15.8409C39.3058 14.5483 39.6505 13.418 40.3465 12.4502C41.0492 11.4758 42.0203 10.7168 43.2599 10.1733C44.5061 9.62969 45.9346 9.35791 47.5454 9.35791C49.1893 9.35791 50.6112 9.633 51.811 10.1832C53.0108 10.7334 53.9356 11.509 54.5852 12.5099C55.2414 13.5042 55.5729 14.6676 55.5795 16H50.2897Z"
        fill="black"
      />
    </svg>
  );
}
