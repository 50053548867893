import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase/firebase';

const events = {
  START_GAME: 'start_game',
};

export default class TrackerEvent {
  static event = events;

  static sendStartGameWithHomeMainButton() {
    logEvent(analytics, events.START_GAME, {
      name: 'home_main_button',
    });
  }
}
