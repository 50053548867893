import Footer from '../components/Footer';
import Header from '../components/Header';

export default function FaqPage() {
  return (
    <>
      <Header />
      <article className="faq">
        <h1>FAQ</h1>

        <ul className="links">
          <section>
            <ul className="faq-list">
              <li>
                <a href="#what">What is Scrum Poker?</a>
              </li>

              <li>
                <a href="#how">How does Scrum Poker work?</a>
              </li>

              <li>
                <a href="#who">Who should participate in Scrum Poker?</a>
              </li>

              <li>
                <a href="#why">
                  Why use Scrum Poker instead of other estimation techniques?
                </a>
              </li>

              <li>
                <a href="#can-i">Can I use Scrum Poker for remote teams?</a>
              </li>

              <li>
                <a href="#what-are">
                  What are the different card decks used in Scrum Poker?
                </a>
              </li>

              <li>
                <a href="#how-to-handle-large">
                  How to handle large differences in team estimates?
                </a>
              </li>

              <li>
                <a href="#how-to-integrate">
                  How to integrate Scrum Poker into my development process?
                </a>
              </li>

              <li>
                <a href="#is-scrum-poker-suitable">
                  Is Scrum Poker suitable for all types of projects?
                </a>
              </li>

              <li>
                <a href="#are-there-specific-tools">
                  Are there specific tools for conducting Scrum Poker online?
                </a>
              </li>
            </ul>
          </section>
        </ul>

        <div className="description">
          <h2 id="what">What is Scrum Poker?</h2>
          <p>
            Scrum Poker is a collaborative estimation technique used in agile
            project management, particularly in Scrum. It involves the entire
            team, including developers, testers, and other stakeholders, working
            together to estimate the effort required to complete a specific task
            or user story. The team uses a set of specially designed cards,
            often featuring numbers or other symbols, to express their consensus
            on the task's complexity or size.
          </p>

          <h2 id="how">How does Scrum Poker work?</h2>
          <p>
            In Scrum Poker, the team gathers to discuss a particular task or
            user story. Each team member then independently selects a card from
            the Scrum Poker deck that represents their estimate of the effort
            involved. After everyone has made their selection, the team reveals
            their cards simultaneously. If there are significant discrepancies
            in estimates, team members discuss the reasons behind their choices.
            This collaborative approach helps to achieve a more accurate and
            well-rounded estimate.
          </p>

          <h2 id="who">Who should participate in Scrum Poker?</h2>
          <p>
            All team members who are involved in the development process, from
            developers and testers to product owners, should participate in
            Scrum Poker. This inclusivity ensures that various perspectives and
            insights are considered during the estimation process, leading to
            more reliable estimates.
          </p>

          <h2 id="why">
            Why use Scrum Poker instead of other estimation techniques?
          </h2>
          <p>
            Scrum Poker fosters open communication and collaboration among team
            members, which can lead to more accurate estimations. Unlike other
            methods where a single person might dominate the decision-making
            process, Scrum Poker encourages every team member to contribute,
            promoting a more democratic and informed approach to estimation.
          </p>

          <h2 id="can-i">Can I use Scrum Poker for remote teams?</h2>
          <p>
            Yes, Scrum Poker can be adapted for remote teams using various
            online tools specifically designed for virtual collaboration. These
            tools allow team members to participate in Scrum Poker sessions from
            different locations, ensuring that the benefits of collaborative
            estimation are not lost in a remote work setting.
          </p>

          <h2 id="what-are">
            What are the different card decks used in Scrum Poker?
          </h2>
          <p>
            Scrum Poker uses different card decks, such as the Fibonacci
            sequence, T-Shirt sizes (XS, S, M, L, XL), or standard numerical
            sequences. Each deck has its own rationale, with the Fibonacci
            sequence often used to reflect the uncertainty and variability
            inherent in estimating larger tasks.
          </p>

          <h2 id="how-to-handle-large">
            How to handle large differences in team estimates?
          </h2>
          <p>
            When there are significant differences in estimates, it's essential
            to facilitate a discussion within the team. This discussion should
            focus on clarifying the task, addressing any misunderstandings, and
            ensuring that everyone has a shared understanding of the work.
            Sometimes, these discussions reveal valuable insights and
            considerations that contribute to more accurate estimates.
          </p>

          <h2 id="how-to-integrate">
            How to integrate Scrum Poker into my development process?
          </h2>
          <p>
            To integrate Scrum Poker into your development process, incorporate
            it into your sprint planning or backlog refinement meetings. Ensure
            that team members are familiar with the technique, and encourage
            open communication during estimation sessions. Over time, the team
            will likely refine their approach to Scrum Poker to better suit
            their workflow and dynamics.
          </p>

          <h2 id="is-scrum-poker-suitable">
            Is Scrum Poker suitable for all types of projects?
          </h2>
          <p>
            While Scrum Poker is effective for many projects, it may be
            particularly well-suited for projects with tasks that can be
            understood and estimated based on the team's collective experience.
            For highly innovative or exploratory projects, the team may need to
            complement Scrum Poker with other techniques to account for
            uncertainties and evolving requirements.
          </p>

          <h2 id="are-there-specific-tools">
            Are there specific tools for conducting Scrum Poker online?
          </h2>
          <p>
            Yes, there are dedicated online tools designed for conducting Scrum
            Poker sessions in virtual or remote environments. Tools like
            Planning Poker or online agile project management platforms often
            include features for collaborative estimation, allowing team members
            to participate in Scrum Poker sessions seamlessly, even when
            physically distant.
          </p>
        </div>
      </article>
      <Footer />
    </>
  );
}
