import { GameStatus, stringToGameStatus } from "../enum/GameStatus";
import User from "./User";

export default class GameData {
  users: Array<User>;
  status: GameStatus;

  constructor(users: Array<User> = [], status: string) {
    this.users = users;
    this.status = stringToGameStatus(status);
  }
}

