import AdsBanners from '../components/AdsBanners';
import Footer from '../components/Footer';
import Header from '../components/Header';
import image from '../image/office.png';

export default function WhatIsScrumPokerPage() {
  return (
    <>
      <Header />
      <article>
        <h1>
          Discover the
          <br />
          Magic of Scrum Poker!
        </h1>
        <p>
          Welcome to the world of Scrum Poker, a dynamic and collaborative
          technique that redefines how teams estimate and plan their projects in
          an agile environment, Scrum Poker, also know as Planning Poker,
          enhances the estimation process by integrating fun and teamwork into
          the equation.
        </p>

        <figure>
          <img src={image} alt="discovery" />
        </figure>

        <h2>Whats is Scrum Poker?</h2>
        <p>
          Scrum Poker is a team-based estimation technique tha utilizes a deck
          of cards with different numerical values. Team members assign these
          values to user stories or tasks, reflecting the relative offort and
          complexity required to complete them. It encourages open discussions
          and ensures everyone´s input is considered during the estimation
          process.
        </p>

        <h2>How to Apply Scrum Poker</h2>
        <p>
          <ol>
            <li>Gather your Agile team.</li>
            <li>Choose a user story or task for estimation.</li>
            <li>
              Each team member selects a card from the deck, indicating their
              estimate privately.
            </li>
            <li>Discuss the estimates and reasoning behind each choice.</li>
            <li>
              Repeat the process until a consensus on the estimate is reached.
            </li>
            <li>Record the agreed-upon estimate.</li>
          </ol>
        </p>

        <h2>Advantages of Scrum Poker</h2>
        <p>
          <ul>
            <li>
              Facilitated Estimation: Scrum Poker simplifies the estimation
              process, making it engaging and efficient for the entire team.
            </li>
            <li>
              Team Involviment: It promotes collaboration and ensures that
              everyone´s opinion is valued during the estimation, resulting in
              more accurate estimates.
            </li>
            <li>
              Easy to Implement: With a straightforward process, Scrum Poker is
              quickly adopted, by teams, echancing overall productivity and
              project planning.
            </li>
            <li>
              Automatic Averaging: The tool automatically calculates the average
              estimate, providing instant insights for better decision-making
              and improved sprint planning.
            </li>
          </ul>
        </p>

        <p>
          Unlock the potential of Scrum Poker and elevate your Agile team´s
          estimation game, ultimately leading to smoother project executions and
          successful sprint deliveries!
        </p>
      </article>
      <AdsBanners />
      <Footer />
    </>
  );
}
