import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import TrackerEvent from '../analytics/TrackerEvent';
import { createGame } from '../firebase/firebaseUtils';
import { useAppContext } from '../appContext';
import Icon, { IconType } from '../components/Icon';
import Footer from '../components/Footer';
import ImageEffortlessEstimation from '../image/effortless-estimation.png';
import ImageSimpleInvitations from '../image/simple-invitations.png';
import ImageAutomatedPointAveraging from '../image/automated-point-averaging.png';
import AdsBanners from '../components/AdsBanners';

export default function HomePage() {
  const { globalState, updateGlobalState } = useAppContext();
  const navigate = useNavigate();

  const startGame = async () => {
    if (globalState.user) {
      try {
        TrackerEvent.sendStartGameWithHomeMainButton();
        await createGame((key) => {
          navigate(`/${key}`);
        });
      } catch (error) {
        console.error('Error writing data:', error);
      }
    } else {
      updateGlobalState({ isLoginModalVisible: true });
    }
  };

  return (
    <>
      <Header />
      <main className="home-page">
        <section id="highlight">
          <h1>
            Sprint Planning
            <br />
            with Scrum Poker Cards
          </h1>
          <p>
            Enhance Collaboration and Accuracy in Sprint Planning.
            <br />
            Align Teams and Optimize Sprint Goals.
          </p>
          <button className="primary" onClick={startGame}>
            Start new game
            <Icon icon={IconType.ARROW_FORWARD} />
          </button>
        </section>
        <section id="features" className="features">
          <div className="content">
            <div className="title">
              <h2>Unlock the Power of Scrum Poker</h2>
              <p>Empower Your Agile Team for Success</p>
            </div>
            <div className="feature">
              <div>
                <h3>Effortless Estimation & Team Fun</h3>
                <p>
                  Simplify task estimation while adding a fun twist. Our
                  platform transforms estimation sessions into engaging and
                  enjoyable experiences, fostering team collaboration and
                  productivity.
                </p>
                <a onClick={startGame}>
                  Start New Game <Icon icon={IconType.ARROW_FORWARD} />
                </a>
              </div>
              <div className="image">
                <img
                  src={ImageEffortlessEstimation}
                  alt="Image Effortless Estimation"
                />
              </div>
            </div>
            <div className="feature">
              <div className="image">
                <img
                  src={ImageSimpleInvitations}
                  alt="Image Simple Invitations"
                />
              </div>
              <div>
                <h3>Simple Invitations, Seamless Participation</h3>
                <p>
                  Invite team members seamlessly, ensuring swift and efficient
                  participation. Enhance teamwork and achieve accurate
                  estimations with our user-friendly invitation process.
                </p>
                <a onClick={startGame}>
                  Start New Game <Icon icon={IconType.ARROW_FORWARD} />
                </a>
              </div>
            </div>
            <div className="feature">
              <div>
                <h3>Instant Insights: Automated Point Averaging</h3>
                <p>
                  Automatically calculate average points, providing instant
                  insights for informed decision-making. Streamline your
                  estimation process and focus on project success with our
                  automated averaging feature.
                </p>
                <a onClick={startGame}>
                  Start New Game <Icon icon={IconType.ARROW_FORWARD} />
                </a>
              </div>
              <div className="image">
                <img
                  src={ImageAutomatedPointAveraging}
                  alt="Image Automated Point Averaging"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="how-to-use" className="steps">
          <div className="title">
            <h2>Effortless Scrum Poker</h2>
            <p>
              Unlock efficient Agile planning with our Scrum Poker tool. Follow
              these three easy steps for a collaborative and streamlined
              planning experience:
            </p>
          </div>
          <div className="content">
            <div>
              <div className="number">
                <p>1</p>
                <span className="line"></span>
              </div>
              <p>
                Kick off your planning by creating a new game. Quick and
                straightforward, allowing you to focus on your Sprint's
                effectiveness.
              </p>
            </div>
            <div>
              <div className="number">
                <p>2</p>
                <span className="line"></span>
              </div>
              <p>
                Involving your team is key. With our tool, inviting participants
                is intuitive. Ensure the presence of essential members for
                comprehensive decision-making.
              </p>
            </div>
            <div>
              <div className="number">
                <p>3</p>
              </div>
              <p>
                The heart of Scrum Poker lies in the votes. Enable your team to
                vote on features effectively and collaboratively, ensuring a
                precise selection aligned with Sprint goals.
              </p>
            </div>
          </div>
          <button className="primary" onClick={startGame}>
            Start new game
            <Icon icon={IconType.ARROW_FORWARD} />
          </button>
        </section>
      </main>
      <AdsBanners />
      <Footer />
    </>
  );
}
