export enum IconType {
  CLOSE = 'close',
  ARROW_FORWARD = 'arrow_forward',
  EXPAND_MORE = 'expand_more',
}

interface IconProps {
  icon: IconType;
  iconClass?: string;
  onClose?: () => void;
}

export default function Icon({ icon, iconClass, onClose }: IconProps) {
  return (
    <span onClick={onClose} className={`${iconClass} material-symbols-rounded`}>
      {icon.toString()}
    </span>
  );
}
