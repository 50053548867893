import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import HomePage from './pages/HomePage.tsx';
import GamePage from './pages/GamePage.tsx';
import ErrorPage from './pages/ErrorPage.tsx';
import WhatIsScrumPokerPage from './pages/WhatIsScrumPokerPage.tsx';
import FaqPage from './pages/FaqPage.tsx';
import PrivacyPage from './pages/PrivacyPage.tsx';
import TermsConditionsPage from './pages/TermsConditionsPage.tsx';

export default function Routes() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <HomePage />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/:gameId',
      element: <GamePage />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/about',
      element: <WhatIsScrumPokerPage />,
      errorElement: <ErrorPage />,
    },

    {
      path: '/faq',
      element: <FaqPage />,
      errorElement: <ErrorPage />,
    },

    {
      path: '/privacy-policy',
      element: <PrivacyPage />,
      errorElement: <ErrorPage />,
    },

    {
      path: '/terms-and-conditions',
      element: <TermsConditionsPage />,
      errorElement: <ErrorPage />,
    },
  ]);

  return <RouterProvider router={router} />;
}
