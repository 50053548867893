import { User } from 'firebase/auth';
import { createContext, useContext, useState, ReactNode } from 'react';

interface GlobalState {
  user?: User | null;
  isLoginModalVisible: boolean;
}

const initialState: GlobalState = {
  isLoginModalVisible: false,
};

interface AppContextProps {
  globalState: GlobalState;
  updateGlobalState: (newState: Partial<GlobalState>) => void;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

interface AppProviderProps {
  children: ReactNode;
}

export function AppProvider({ children }: AppProviderProps) {
  const [globalState, setGlobalState] = useState<GlobalState>(initialState);

  const updateGlobalState = (newState: Partial<GlobalState>) => {
    setGlobalState((prevState) => ({ ...prevState, ...newState }));
  };

  return (
    <AppContext.Provider value={{ globalState, updateGlobalState }}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext(): AppContextProps {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext deve ser usado dentro de um AppProvider');
  }
  return context;
}
